var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":""}},[_c('modal-title',{attrs:{"text":"NOTES LOG"}}),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.tableData.headers.concat( [{ text: '', value: 'action', sortable: false, align: 'right' }]
      ),"items":_vm.tableData.items,"fixed-header":"","options.sync":"paginitation","hide-default-footer":true,"height":_vm.tableData.height},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('CiHeading',{staticClass:"pl-4 my-6",attrs:{"item":_vm.catalogItem}})]},proxy:true},{key:"item.action",fn:function(ref){
      var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"top":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mx-2",attrs:{"small":"","color":"error"}},on),[_vm._v(" mdi-delete ")])]}}],null,true),model:{value:(_vm.menuConfDelete),callback:function ($$v) {_vm.menuConfDelete=$$v},expression:"menuConfDelete"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',{staticClass:"title primary--text"},[_vm._v(" CONFIRM DELETE ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"warning"}},[_vm._v("CANCEL")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.noteDelete(item)}}},[_vm._v("CONFIRM")])],1)],1)],1),_c('v-icon',{staticClass:"mx-2",attrs:{"small":"","color":"warning"},on:{"click":function($event){return _vm.noteEdit(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"no-data",fn:function(){return [_vm._v(" NO NOTES ")]},proxy:true}])})],1),_c('v-card-actions',[_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"text":"","color":"success"}},on),[_vm._v("+ ADD")])]}}]),model:{value:(_vm.modalEditNote),callback:function ($$v) {_vm.modalEditNote=$$v},expression:"modalEditNote"}},[_c('editNote',{key:_vm.modalEditNote,attrs:{"catalogItem":_vm.catalogItem,"noteEditing":_vm.noteEditing},on:{"close":_vm.onEditNoteClose,"saveNote":_vm.onSaveNote}})],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("CLOSE")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }